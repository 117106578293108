import 'leaflet/dist/leaflet.css';
import './App.css';
import { Routes, Route, HashRouter } from "react-router-dom";
import AuthLayout from './layout/AuthLayout';
import DashboardLayout from './layout/DashboardLayout';
import ReceiptLayout from './layout/RecieptLayout';

import LoginPage from './pages/auth/LoginPage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import RegisterPage from './pages/auth/RegisterPage';

import DashboardPage from './pages/dashboard/DashboardPage';
import TariffPage from './pages/tariff/TariffPage';
import TransactionsPage from './pages/transactions/TransactionsPage';
import CustomersPage from './pages/customers/CutomersPage';
import AssetPage from './pages/asset/AssetPage';
import UsersPage from './pages/settings/UsersPage';
import UpdatePasswordPage from './pages/auth/UpdatePasswordPage';
import VerifyEmailPage from './pages/auth/VerifyEmailPage';
import TrackingPage from './pages/tracking';
import ReceiptPage from './pages/transactions/ReceiptPage';
import TransactionDetailPage from './pages/transactions/TransactionDetailPage';
import AssetDetailPage from './pages/asset/AssetDetailPage';
import AddAssetPage from './pages/asset/AddAssetPage';
import EditAssetPage from './pages/asset/EditAssetPage';
import ContainerTrackingPage from './pages/tracking/ContainerTracking';
import VerificationPage from './pages/transactions/VerificationPage';
import ReportsPage from './pages/reports/ReportsPage';
import ReportsByTariffPage from './pages/reports/ReportsByTariffPage';
import ReportsByTypePage from './pages/reports/ReportsByTypePage';
import RealTimePage from './pages/reports/RealTimePage';
import ReportsByPortPage from './pages/reports/ReportsByPortPage';

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/transaction" element={<ReceiptLayout />}>
          <Route path="receipt/id/:transactionId/userid/:userId" element={<ReceiptPage />} />
          <Route path="verify/id/:transactionId/userid/:userId" element={<VerificationPage />} />
        </Route>
        <Route path="/" element={<DashboardLayout />}>
          <Route index path="/" element={<DashboardPage />} />
          <Route path="/transactions" element={<TransactionsPage />} />
          <Route path="/real-time" element={<RealTimePage />} />
          <Route path="/transaction/detail/:transactionId/:createdBy" element={<TransactionDetailPage />} />
          <Route path="/tariff" element={<TariffPage />} />
          <Route path="/customers" element={<CustomersPage />} />
          <Route path="/asset" element={<AssetPage />} />
          <Route path="/add-asset" element={<AddAssetPage />} />
          <Route path="/edit-asset/:id" element={<EditAssetPage />} />
          <Route path="/asset-detail/:id" element={<AssetDetailPage />} />
          <Route path="/tracking" element={<TrackingPage />} />
          <Route path="/container-tracking" element={<ContainerTrackingPage />} />
          <Route path="/users" element={<UsersPage />} />
          <Route path="/reports" element={<ReportsPage />} />
          <Route path="/report-tariff" element={<ReportsByTariffPage />} />
          <Route path="/report-by-type" element={<ReportsByTypePage />} />
          <Route path="/report-by-port" element={<ReportsByPortPage />} />
        </Route>
        <Route path="/auth" element={<AuthLayout />}>
          <Route path="register" element={<RegisterPage />} />
          <Route path="verify-email/token/:token/email/:email" element={<VerifyEmailPage />} />
          <Route index path="login" element={<LoginPage />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="update-password/token/:token/email/:email" element={<UpdatePasswordPage />} />
          {/* <Route path="register" element={<RegisterPage />} />
              <Route path="forgot-password" element={<ForgotPasswordPage />} />
              <Route path="update-password" element={<RegisterPasswordPage />} /> */}
        </Route>

        <Route path="*" element={<h2>404 Not Found</h2>} />
      </Routes>
    </HashRouter>
  );
}

export default App;
