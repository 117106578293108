import React, { useState, useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CloseIcon from "@mui/icons-material/Close";

import Title from "../../components/text/Title";
import CustomButton from "../../components/button/CustomButton";
import MessageAlert from "../../components/alerts/MessageAlert";
import { handleAlertClose } from '../../components/alerts/alertUtils';
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomInput from "../../components/input/CustomInput";
import SelectInput from "../../components/input/SelectInput";
import ReusableTable from "../../components/Table/CustomTable";
import { Typography } from "@mui/material";
import CustomModal from "../../components/modal/CustomModal";

export default function AssetDetailPage() {
    const { id } = useParams();
    const profile = useSelector((store) => store.user.profile);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("error");

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [assetHistory, setAssetHistory] = useState([]);
    
    const [currentItem, setCurrentItem] = useState(null);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [openHistoryAddModal, setOpenHistoryAddModal] = useState(false);
    const [openHistoryEditModal, setOpenHistoryEditModal] = useState(false);
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [historyStatus, setHistoryStatus] = useState("");
    const [assetFeeAmount, setAssetFeeAmount] = useState("");
    const [assetHistoryDescription, setAssetHistoryDescription] = useState("");
    const [fromEdit, setFromEdit] = useState("");
    const [toEdit, setToEdit] = useState("");
    const [historyStatusEdit, setHistoryStatusEdit] = useState("");
    const [assetFeeAmountEdit, setAssetFeeAmountEdit] = useState("");
    const [assetHistoryDescriptionEdit, setAssetHistoryDescriptionEdit] = useState("");
    const [currentHistoryItem, setCurrentHistoryItem] = useState("");

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${window.backendUrl}/api/v1/assets/id/${id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result?.code === 200) {
                    setData(result?.data);
                    setCurrentItem(result?.data?.asset);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, []);

    const tableAction = (item) => {
        return(
            <button 
                style={{backgroundColor: "#00A758", color: "#fff", cursor: "pointer", padding: "5px 10px", borderRadius: 2, border: 0}} 
                onClick={() => {
                    setCurrentHistoryItem(item);
                    setFromEdit(item?.from);
                    setToEdit(item?.to);
                    setHistoryStatusEdit(item?.status);
                    setAssetFeeAmountEdit(item?.amount);
                    setAssetHistoryDescriptionEdit(item?.description);
                    setOpenHistoryEditModal(true);
                }}
                >
                Edit
            </button>
        );
    }

    const handleAddHistory = () => {
        if (!from || !to || !historyStatus || !assetFeeAmount) {
            setAlertMessage("All fields are required!");
            setAlertType("error");
            setAlertOpen(true);
        } else {
            setButtonLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                idNumber: currentItem?.idNumber, from, to, status: historyStatus, amount: assetFeeAmount, description: assetHistoryDescription
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(`${window.backendUrl}/api/v1/assets/history/add`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    console.log(result, data);
                    if (result?.code === 200) {
                        //setAssetHistory(result?.data);
                        setData({...data, assetHistory: result?.data});
                        setAlertMessage(result?.message);
                        setAlertType("success");
                        setAlertOpen(true);
                        setOpenHistoryAddModal(false);
                    } else {
                        setAlertMessage(result?.message);
                        setAlertType("error");
                        setAlertOpen(true);
                    }
                    setButtonLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setAlertMessage("An error occured, try again !");
                    setAlertType("error");
                    setAlertOpen(true);
                    setButtonLoading(false);
                });
        }
    }

    const handleEditHistory = () => {
        if (!fromEdit || !toEdit || !historyStatusEdit || !assetFeeAmountEdit) {
            setAlertMessage("All fields are required!");
            setAlertType("error");
            setAlertOpen(true);
        } else {
            setButtonLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                id: currentHistoryItem?._id, idNumber: currentHistoryItem?.idNumber, from: fromEdit, to: toEdit, status: historyStatusEdit, amount: assetFeeAmountEdit, description: assetHistoryDescriptionEdit
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(`${window.backendUrl}/api/v1/assets/history/edit`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result?.code === 200) {
                        setAssetHistory(result?.data);
                        setData({...data, assetHistory: result?.data});
                        setAlertMessage(result?.message);
                        setAlertType("success");
                        setAlertOpen(true);
                        setOpenHistoryEditModal(false);
                    } else {
                        setAlertMessage(result?.message);
                        setAlertType("error");
                        setAlertOpen(true);
                    }
                    setButtonLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setAlertMessage("An error occured, try again !");
                    setAlertType("error");
                    setAlertOpen(true);
                    setButtonLoading(false);
                });
        }
    }

    return (
        <>
            <MessageAlert
                message={alertMessage}
                open={alertOpen}
                onClose={handleAlertClose(setAlertOpen)}
                type={alertType}
            />
            <CustomModal openModal={openHistoryAddModal} setOpenModal={setOpenHistoryAddModal} >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ marginBottom: "20px" }}>
                        Hire Asset
                    </Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => setOpenHistoryAddModal(false)}>
                        <CloseIcon style={{ fontSize: "20px", color: "#1D8A50" }} />
                    </div>
                </div>
                <CustomInput
                    label="From"
                    placeholder="Enter the asset from"
                    value={from}
                    onChange={(e) => setFrom(e.target.value)}
                />
                <CustomInput
                    label="To"
                    placeholder="Enter the asset to"
                    value={to}
                    onChange={(e) => setTo(e.target.value)}
                />
                <CustomInput
                    label="Fee Amount"
                    placeholder="Enter the fee amount"
                    value={assetFeeAmount}
                    onChange={(e) => setAssetFeeAmount(e.target.value)}
                />
                <CustomInput
                    label="Hire to"
                    placeholder="Enter the hire to"
                    value={assetHistoryDescription}
                    onChange={(e) => setAssetHistoryDescription(e.target.value)}
                />
                <SelectInput
                    data={[
                        { id: 1, name: "Prepare to Move" },
                        { id: 2, name: "On Route" },
                        { id: 3, name: "At Destination" },
                        { id: 4, name: "Returned" }
                    ]}
                    label="Status"
                    value={historyStatus}
                    onChange={(e) => {
                        setHistoryStatus(e.target.value);
                    }}
                    placeholder="Select Payment"
                />
                <CustomButton loading={buttonLoading} disabled={buttonLoading} onClick={() => handleAddHistory()}>
                    Submit
                </CustomButton>
            </CustomModal>
            <CustomModal openModal={openHistoryEditModal} setOpenModal={setOpenHistoryEditModal} >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ marginBottom: "20px" }}>
                        Edit Asset History
                    </Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => setOpenHistoryEditModal(false)}>
                        <CloseIcon style={{ fontSize: "20px", color: "#1D8A50" }} />
                    </div>
                </div>
                <CustomInput
                    label="From"
                    placeholder="Enter the asset from"
                    value={fromEdit}
                    onChange={(e) => setFromEdit(e.target.value)}
                />
                <CustomInput
                    label="To"
                    placeholder="Enter the asset to"
                    value={toEdit}
                    onChange={(e) => setToEdit(e.target.value)}
                />
                <CustomInput
                    label="Fee Amount"
                    placeholder="Enter the fee amount"
                    value={assetFeeAmountEdit}
                    onChange={(e) => setAssetFeeAmountEdit(e.target.value)}
                />
                <CustomInput
                    label="Hire to"
                    placeholder="Enter the hire to"
                    value={assetHistoryDescriptionEdit}
                    onChange={(e) => setAssetHistoryDescriptionEdit(e.target.value)}
                />
                <SelectInput
                    data={[
                        { id: 1, name: "Prepare to Move" },
                        { id: 2, name: "On Route" },
                        { id: 3, name: "At Destination" },
                        { id: 4, name: "Returned" },
                    ]}
                    label="Status"
                    value={historyStatusEdit}
                    onChange={(e) => {
                        setHistoryStatusEdit(e.target.value);
                    }}
                />
                <CustomButton loading={buttonLoading} disabled={buttonLoading} onClick={() => handleEditHistory()}>
                    Update
                </CustomButton>
            </CustomModal>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 20, marginBottom: 20 }}>
                <Title>Asset Details</Title>
            </div>
            {loading ?
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <CircularProgress color="success" />
                </Box>
                :
                <>
                    <table width={"100%"} border={0} cellPadding={10}>
                        <tr>
                            <td align="right" width={"50%"}><label style={{ fontWeight: 600 }}>Asset ID: </label></td>
                            <td>{data?.asset?.idNumber}</td>
                        </tr>
                        <tr>
                            <td align="right"><label style={{ fontWeight: 600 }}>Name: </label></td>
                            <td>{data?.asset?.name}</td>
                        </tr>
                        <tr>
                            <td align="right"><label style={{ fontWeight: 600 }}>Make: </label></td>
                            <td>{data?.asset?.make}</td>
                        </tr>
                        <tr>
                            <td align="right"><label style={{ fontWeight: 600 }}>Model: </label></td>
                            <td>{data?.asset?.model}</td>
                        </tr>
                        <tr>
                            <td align="right"><label style={{ fontWeight: 600 }}>Machinery Type: </label></td>
                            <td>{data?.asset?.machineryType}</td>
                        </tr>
                        <tr>
                            <td align="right"><label style={{ fontWeight: 600 }}>Description: </label></td>
                            <td>{data?.asset?.description}</td>
                        </tr>
                        <tr>
                            <td align="right"><label style={{ fontWeight: 600 }}>Location: </label></td>
                            <td>{data?.asset?.location}</td>
                        </tr>
                        <tr>
                            <td align="right"><label style={{ fontWeight: 600 }}>Status: </label></td>
                            <td>{data?.asset?.status}</td>
                        </tr>

                        <tr>
                            <td align="right"><label style={{ fontWeight: 600 }}>Assigned Personel: </label></td>
                            <td>{data?.asset?.assignedPersonel}</td>
                        </tr>
                        <tr>
                            <td align="right"><label style={{ fontWeight: 600 }}>Personel Phone: </label></td>
                            <td>{data?.asset?.personelPhone}</td>
                        </tr>
                        <tr>
                            <td align="right"><label style={{ fontWeight: 600 }}>Personel Email: </label></td>
                            <td>{data?.asset?.personelEmail}</td>
                        </tr>
                    </table>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", }}>
                        <h3>Asset History</h3>
                        <div style={{ width: "200px",}}>
                            <CustomButton size="small" onClick={() => setOpenHistoryAddModal(true)}>
                                Hire Asset
                            </CustomButton>
                        </div>
                    </div>
                    <div style={{width: '100%'}}>
                        <ReusableTable
                            tableHeader={
                                [
                                    { id: 'idNumber', label: 'ID', numeric: false },
                                    { id: 'from', label: 'From', numeric: false },
                                    { id: 'to', label: 'To', numeric: false },
                                    { id: 'amount', label: 'Amount', numeric: false },
                                    { id: 'description', label: 'Hire to', numeric: false },
                                    { id: 'status', label: 'Status', numeric: false },
                                ]
                            }
                            tableData={data?.assetHistory}
                            tableAction={tableAction}
                            //rowClickAction={rowClickAction}
                        />
                    </div>

                </>
            }
        </>
    );
}