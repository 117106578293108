import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import ReusableTable from "../../components/Table/CustomTable";
import Title from "../../components/text/Title";
import CustomButton from "../../components/button/CustomButton";
import CustomInput from "../../components/input/CustomInput";
import CustomModal from "../../components/modal/CustomModal";
import MessageAlert from "../../components/alerts/MessageAlert";
import { handleAlertClose } from '../../components/alerts/alertUtils';
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function CustomersPage() {
    const profile = useSelector((store) => store.user.profile);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get('name');
    const [openModal, setOpenModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [currentItem, setCurrentItem] = useState({});
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("error");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyAddress, setCompanyAddress] = useState("");
    const [companyNameEdit, setCompanyNameEdit] = useState("");
    const [companyAddressEdit, setCompanyAddressEdit] = useState("");
    const [nameEdit, setNameEdit] = useState("");
    const [emailEdit, setEmailEdit] = useState("");
    const [phoneEdit, setPhoneEdit] = useState("");
    
    const tableAction = (row) => {
        return (
        <button 
            style={{backgroundColor: "#00A758", color: "#fff", cursor: "pointer", padding: "5px 10px", borderRadius: 2, border: 0}} 
            onClick={() => {
                setCurrentItem(row);
                setNameEdit(row?.name);
                setEmailEdit(row?.email);
                setPhoneEdit(row?.phone);
                setOpenEditModal(true);
            }}>
            Edit
        </button>
        );
    };

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${window.backendUrl}/api/v1/customers/all`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result?.code === 200) {
                    setData(result?.data);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, []);

    const handleAdd = () => {
        if (!name || !email || !phone) {
            setAlertMessage("All fields are required!");
            setAlertType("error");
            setAlertOpen(true);
        } else {
            setButtonLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                name, email, phone, companyAddress, companyName
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(`${window.backendUrl}/api/v1/customers/add`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result?.code === 200) {
                        setData(result?.data);
                        setAlertMessage(result?.message);
                        setAlertType("success");
                        setAlertOpen(true);
                        setOpenModal(false);
                    } else {
                        setAlertMessage(result?.message);
                        setAlertType("error");
                        setAlertOpen(true);
                    }
                    setButtonLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setAlertMessage("An error occured, try again !");
                    setAlertType("error");
                    setAlertOpen(true);
                    setButtonLoading(false);
                });
        }
    }

    const handleEdit = () => {
        if (!nameEdit || !emailEdit || !phoneEdit) {
            setAlertMessage("All fields are required!");
            setAlertType("error");
            setAlertOpen(true);
        } else {
            setButtonLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                id: currentItem?._id, 
                name: nameEdit, 
                phone: phoneEdit, 
                email: emailEdit
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(`${window.backendUrl}/api/v1/customers/edit`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result?.code === 200) {
                        setData(result?.data);
                        setAlertMessage(result?.message);
                        setAlertType("success");
                        setAlertOpen(true);
                        setOpenEditModal(false);
                    } else {
                        setAlertMessage(result?.message);
                        setAlertType("error");
                        setAlertOpen(true);
                    }
                    setButtonLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setAlertMessage("An error occured, try again !");
                    setAlertType("error");
                    setAlertOpen(true);
                    setButtonLoading(false);
                });
        }
    }

    const statusType = {
        "active": 1,
        "inactive": 0
    };
    
    return(
        <>
            <MessageAlert
                message={alertMessage}
                open={alertOpen}
                onClose={handleAlertClose(setAlertOpen)}
                type={alertType}
            />
            <CustomModal openModal={openModal} setOpenModal={setOpenModal} >
                <div
                    style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ marginBottom: "20px" }}>
                        Add Customer
                    </Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => setOpenModal(false)}>
                        <CloseIcon style={{ fontSize: "20px", color: "#1D8A50" }} />
                    </div>
                </div>
                <CustomInput 
                    label="Company Name"
                    placeholder="Enter the company name"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                />
                <CustomInput 
                    label="Company Representative"
                    placeholder="Enter the company representative full name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <CustomInput 
                    label="Email"
                    placeholder="Enter the customer email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <CustomInput 
                    label="Phone Number"
                    placeholder="Enter the customer phone number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
                <CustomInput 
                    label="Company address"
                    placeholder="Enter the company address"
                    value={companyAddress}
                    onChange={(e) => setCompanyAddress(e.target.value)}
                />
                <CustomButton loading={buttonLoading} disabled={buttonLoading} onClick={() => handleAdd()}>
                    Submit
                </CustomButton>
            </CustomModal>
            <CustomModal openModal={openEditModal} setOpenModal={setOpenEditModal} >
                <div
                    style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ marginBottom: "20px" }}>
                        Edit Customer
                    </Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => setOpenEditModal(false)}>
                        <CloseIcon style={{ fontSize: "20px", color: "#1D8A50" }} />
                    </div>
                </div>
                <CustomInput 
                    label="Company name"
                    placeholder="Enter the company name"
                    value={companyNameEdit}
                    onChange={(e) => setCompanyNameEdit(e.target.value)}
                />
                <CustomInput 
                    label="Company Representative"
                    placeholder="Enter the company representative full name"
                    value={nameEdit}
                    onChange={(e) => setNameEdit(e.target.value)}
                />
                <CustomInput 
                    label="Email"
                    placeholder="Enter the customer email address"
                    value={emailEdit}
                    onChange={(e) => setEmailEdit(e.target.value)}
                />
                <CustomInput 
                    label="Phone Number"
                    placeholder="Enter the customer phone number"
                    value={phoneEdit}
                    onChange={(e) => setPhoneEdit(e.target.value)}
                />
                <CustomInput 
                    label="Company Address"
                    placeholder="Enter the company address"
                    value={companyAddressEdit}
                    onChange={(e) => setCompanyAddressEdit(e.target.value)}
                />
                <CustomButton loading={buttonLoading} disabled={buttonLoading} onClick={() => handleEdit()}>
                    Update
                </CustomButton>
            </CustomModal>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 20, marginBottom: 20}}>
                <Title>Customers {status && `(${status})`}</Title>
                <div style={{width: "150px"}}>
                    <CustomButton onClick={() => setOpenModal(true)}>
                        Add Customer
                    </CustomButton>
                </div>
            </div>
            {loading ? 
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <CircularProgress color="success" />
                </Box>
                :
                <ReusableTable 
                    tableHeader={
                        [
                            { id: 'name', label: 'Name', numeric: false },
                            { id: 'phone', label: 'Phone', numeric: false },
                            { id: 'email', label: 'Email', numeric: false },
                        ]
                    }
                    tableData={status ? data.filter(i => i.status === statusType[status.toLowerCase()]) : data}
                    tableAction={tableAction} 
                />
            }
        </>
    );
}