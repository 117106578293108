import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import CustomButton from '../button/CustomButton';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PrintIcon from '@mui/icons-material/Print';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function ReusableTableHead({ headers, order, orderBy, onSelectAllClick, numSelected, rowCount, onRequestSort, tableAction }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell> */}
        {headers.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {
          tableAction &&
          <TableCell>
            <TableSortLabel>
              Action
            </TableSortLabel>
          </TableCell>
        }
      </TableRow>
    </TableHead>
  );
}

ReusableTableHead.propTypes = {
  headers: PropTypes.array.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function ReusableTable({ tableHeader, tableData, tableAction, rowClickAction, isReport, reportTotal, datePickerHandler, printHandler, currency }) {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState(tableHeader[0].id);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchText, setSearchText] = React.useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = tableData.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

  // Filter the rows based on searchText before displaying
  const filteredRows = React.useMemo(() => {
    if (!searchText) return tableData;

    return tableData.filter(row =>
      tableHeader.some(header =>
        row[header.id]?.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [searchText, tableData, tableHeader]);

  const visibleRows = React.useMemo(
    () =>
      [...filteredRows]
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, filteredRows],
  );

  // const visibleRows = React.useMemo(
  //   () =>
  //     [...tableData]
  //       .sort(getComparator(order, orderBy))
  //       .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
  //   [order, orderBy, page, rowsPerPage, tableData],
  // );

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 20, borderBottom: '1px solid grey', marginBottom: 10, }}>
          <h3 style={{ margin: 0, padding: 0 }}>
            {isReport ?
              <>
                <span style={{fontWeight: 400}}>Generated Amount:</span> {currency} {reportTotal?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                <br />
                <span style={{fontWeight: 400}}>Total Transactions:</span> {tableData.length}
              </>
              :
              <><span style={{fontWeight: 400}}>Total:</span> {tableData.length}</>
            }
          </h3>
          <div style={{ width: '40%', display: 'flex', flexDirection: 'row', gap: '10px' }}>
            <input
              onChange={(e) => setSearchText(e.target.value)}
              type='text'
              placeholder='Search...'
              style={
                isReport ? { width: '400%', height: '45px', borderRadius: 8, border: '1px solid grey', padding: 10, boxSizing: 'border-box' }
                  :
                  { width: '100%', height: '45px', borderRadius: 8, border: '1px solid grey', padding: 10, boxSizing: 'border-box' }
              }
            />
            {isReport &&
              <>
                <CustomButton onClick={datePickerHandler}>
                  <CalendarMonthIcon />
                </CustomButton>
                <CustomButton onClick={printHandler}>
                  <PrintIcon />
                </CustomButton>
              </>
            }
          </div>
        </div>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            size="medium"
          >
            <ReusableTableHead
              headers={tableHeader}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={tableData.length}
              tableAction={tableAction}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = selected.includes(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell> */}
                    {tableHeader.map((column) => (
                      <TableCell
                        onClick={(event) => {
                          rowClickAction && rowClickAction(row);
                          // handleClick(event, row.id);
                        }}
                        key={column.id} align={column.numeric ? 'left' : 'left'}>
                        {column.numeric ? (row[column.id])?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : row[column.id]}
                        {/* {column.numeric ? row[column.id].toFixed(2) : row[column.id]} */}
                      </TableCell>
                    ))}
                    <TableCell align="left">
                      {tableAction && tableAction(row)}
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={tableHeader.length + 2} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

ReusableTable.propTypes = {
  tableHeader: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  tableAction: PropTypes.func,
};
