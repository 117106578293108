import React, { useState, useEffect, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
//import PaystackPop from '@paystack/inline-js';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useReactToPrint } from 'react-to-print';
import { styled } from "@mui/system";

import ReusableTable from "../../components/Table/CustomTable";
import Title from "../../components/text/Title";
import CustomButton from "../../components/button/CustomButton";
import SelectInput from "../../components/input/SelectInput";
import CustomInput from "../../components/input/CustomInput";
import CustomModal from "../../components/modal/CustomModal";
import MessageAlert from "../../components/alerts/MessageAlert";
import { handleAlertClose } from '../../components/alerts/alertUtils';
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Checkbox } from '@mui/material';
import Logo from "../../assets/logo.png";

export default function TransactionsPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const name = decodeURIComponent(queryParams.get('name'));
    const type = decodeURIComponent(queryParams.get('type'));
    const agentName = decodeURIComponent(queryParams.get('agentName'));
    const profile = useSelector((store) => store.user.profile);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("error");
    const [openModal, setOpenModal] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [currentItem, setCurrentItem] = useState({});
    const [isChecked, setIsChecked] = useState(true);

    const [data, setData] = useState([]);
    const [tariffData, setTariffData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openModalDate, setOpenModalDate] = useState(false);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${window.backendUrl}/api/v1/transactions/all`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result?.code === 200) {
                    const sortedData = result?.data?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                    console.log(sortedData);
                    setData(sortedData);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${window.backendUrl}/api/v1/tariff/all`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result?.code === 200) {
                    setTariffData(result?.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const tableAction = (row) => {
        return (
            <button
                style={{ backgroundColor: "#00A758", color: "#fff", cursor: "pointer", padding: "5px 10px", borderRadius: 2, border: 0 }}
                onClick={() => {
                    setCurrentItem(row);
                    //setOpenDetailModal(true);
                    navigate(`/transaction/detail/${row?.transactionId}/${row.createdBy}`);
                }}
            >
                View
            </button>
        );
    };

    const rowClickAction = (row) => {
        navigate(`/transaction/detail/${row?.transactionId}/${row?.createdBy}`);
    }


    const [selectedPayment, setSelectedPayment] = useState('');
    const [portName, setPortName] = useState('Apapa');
    const [selectedPaymentObject, setSelectedPaymentObject] = useState('');

    const handleSelectPayment = (itemId) => {
        setSelectedPayment(itemId);
        const selected = tariffData.filter(item => item.name === itemId);
        const selectedObj = selected[0];
        setSelectedPaymentObject(selectedObj);
    }

    const [customerName, setCustomerName] = useState("");
    const [customerEmail, setCustomerEmail] = useState("");
    const [customerPhone, setCustomerPhone] = useState("");
    const [itemQuantity, setItemQuantity] = useState("");
    const [selectedTabCategory, setSelectedTabCategory] = useState('NGN');

    useEffect(() => {
        setCustomerName(profile?.name);
        setCustomerEmail(profile?.email);
    }, [profile]);

    const handlePay = async () => {
        if (!customerName || !customerEmail || !customerPhone || !itemQuantity || !selectedPaymentObject) {
            setAlertMessage("All fields are required!");
            setAlertType("error");
            setAlertOpen(true);
        } else {
            setButtonLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                customerName, customerEmail, customerPhone, itemQuantity, itemId: selectedPaymentObject?._id, portName, isChecked
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(`${window.backendUrl}/api/v1/payment/generate-access-code`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    //console.log(result);
                    if (result?.code === 200) {
                        //const accessCode = result?.data?.data?.access_code;
                        const authorizationUrl = result?.data?.data?.authorization_url;
                        setOpenModal(false);
                        window.location = authorizationUrl;
                        // const popup = new PaystackPop();
                        // setOpenModal(false);
                        // popup.resumeTransaction(accessCode);
                    } else {
                        setAlertMessage(result?.message);
                        setAlertType("error");
                        setAlertOpen(true);
                    }
                    setButtonLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setAlertMessage("An error occured, try again !");
                    setAlertType("error");
                    setAlertOpen(true);
                    setButtonLoading(false);
                });
        }
    }

    const [filteredData, setFilteredData] = useState([]);
    console.log(filteredData);
    useEffect(() => {
        if(!loading){
            if(type !== 'null'){
                if(type === 'tariff'){
                    setFilteredData(name ? data.filter(i => i.itemName === name) : data);
                } else if(type === 'port') {
                    setFilteredData(name ? data.filter(i => i.portName === name) : data);
                } else if(type === 'agent') {
                    setFilteredData(name ? data.filter(i => i.createdBy === name) : data);
                } else {
                    setFilteredData(name !== 'null' ? data.filter(i => i.portName === name) : data);
                }
            } else {
                setFilteredData(name !== 'null' ? data.filter(i => i.portName === name) : data);
            }
        }
    }, [name, type, loading]);

    const PrintableComponent = () => {
        return (
            <div style={{ border: '0px solid red', width: '100%', padding: '20px', boxSizing: 'border-box' }}>
                <div style={{ textAlign: 'center' }}><img src={Logo} alt="Logo" width={100} style={{ alignSelf: "center" }} /></div>
                <h2 style={{ color: "#00701F", textAlign: "center", margin: 0, padding: 0 }}>NIGERIAN PORTS AUTHORITY</h2>
                <h2 style={{ textAlign: 'center', marginTop: 0, paddingTop: 0 }}>Report (All)</h2>
                <h3 style={{ textAlign: 'center', marginTop: 0, paddingTop: 0,}}>{name}</h3>
                <table width={"100%"}>
                    <tr>
                        <td align="left" style={{ paddingBottom: '15px', width: "50%" }}><b>Total Items:</b> {filteredData.length}</td>
                        <td align="right" style={{ paddingBottom: '15px', width: "50%" }}><b>Total Amount:</b> {filteredData.reduce((sum, item) => sum + (item.amount || 0), 0)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    </tr>
                </table>
                <table width={"100%"} border={0}>
                <tr className="header">
                    <td align="center">S/N</td>
                        <td align="center">Transaction_ID</td>
                        <td align="center">Port_Name</td>
                        <td align="center">Customer_Name</td>
                        <td align="center" style={{width: "50px"}}>Customer_Email</td>
                        <td align="center" style={{width: "200px"}}>Item</td>
                        <td align="center">Quantity</td>
                        <td align="center">Currency</td>
                        <td align="center">Amount</td>
                        <td align="center">Date</td>
                    </tr>
                    {filteredData?.map((item, index) => (
                        <tr key={index} className={(index + 1) % 2 === 0 ? 'even' : 'odd'}>
                            <td>{index + 1}</td>
                            <td>{item?.transactionId}</td>
                            <td>{item?.portName}</td>
                            <td>{item?.customerName}</td>
                            <td>{item?.customerEmail}</td>
                            <td>{item?.itemName}</td>
                            <td>{item?.itemQuantity}</td>
                            <td>{item?.currency}</td>
                            <td>{item?.amount?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                            <td>{item?.createdAt}</td>
                        </tr>
                    ))}
                </table>
            </div>
        );
    }

    const contentRef = useRef(null);
    const handlePrint = useReactToPrint({ contentRef });
    const [showDateTitle, setShowDateTitle] = useState(false);

    const handleDateFilter = async () => {
        if(!dateFrom || !dateTo){
            setAlertMessage("Date range is required!");
            setAlertOpen(true);
        } else {
            const dateFromConverted = new Date(dateFrom);
            const dateToConverted = new Date(dateTo);

            const filteredDataDate = data.filter(item => {
                const createdAt = new Date(item.createdAt);
                return createdAt >= dateFromConverted && createdAt <= dateToConverted;
            });
            setFilteredData(filteredDataDate);
            setOpenModalDate(false);
            setShowDateTitle(true);
        }
    }

    return (
        <>
            <MessageAlert
                message={alertMessage}
                open={alertOpen}
                onClose={handleAlertClose(setAlertOpen)}
                type={alertType}
            />
            <CustomModal openModal={openModalDate} setOpenModal={setOpenModalDate}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '15px', boxSizing: 'border-box'}}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '15px', width: '50%'}}>
                        <input type="date" onChange={(e) => setDateFrom(e.target.value)} style={{ width: '100%', height: '45px', borderRadius: 8, border: '1px solid grey', padding: 10, boxSizing: 'border-box' }} />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '15px', width: '50%'}}>
                        <input type="date" onChange={(e) => setDateTo(e.target.value)} style={{ width: '100%', height: '45px', borderRadius: 8, border: '1px solid grey', padding: 10, boxSizing: 'border-box' }} />
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '15px', boxSizing: 'border-box', marginTop: '20px',}}>
                    <CustomButton onClick={() => handleDateFilter()}>Filter</CustomButton>
                </div>
            </CustomModal>
            <CustomModal openModal={openDetailModal} setOpenModal={setOpenDetailModal}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ marginBottom: "20px" }}>
                        Transaction Details
                    </Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => setOpenDetailModal(false)}>
                        <CloseIcon style={{ fontSize: "20px", color: "#1D8A50" }} />
                    </div>
                </div>
                <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <label style={{ fontWeight: 600 }}>Port</label>
                    <div style={{ border: "0px solid #ccc", width: "100%", height: "auto", borderRadius: "4px", paddingLeft: 10, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {currentItem?.portName}
                    </div>
                </div>
                <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <label style={{ fontWeight: 600 }}>Transaction ID</label>
                    <div style={{ border: "0px solid #ccc", width: "100%", height: "auto", borderRadius: "4px", paddingLeft: 10, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {currentItem?.transactionId}
                    </div>
                </div>
                <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <label style={{ fontWeight: 600 }}>Customer</label>
                    <div style={{ border: "0px solid #ccc", width: "100%", height: "auto", borderRadius: "4px", paddingLeft: 10, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {currentItem?.customerName} ({currentItem?.customerEmail})
                    </div>
                </div>
                <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <label style={{ fontWeight: 600 }}>Item</label>
                    <div style={{ border: "0px solid #ccc", width: "100%", height: "auto", borderRadius: "4px", paddingLeft: 10, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {currentItem?.itemName}
                    </div>
                </div>
                <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <label style={{ fontWeight: 600 }}>Quantity</label>
                    <div style={{ border: "0px solid #ccc", width: "100%", height: "auto", borderRadius: "4px", paddingLeft: 10, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {currentItem?.itemQuantity}
                    </div>
                </div>
                <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <label style={{ fontWeight: 600 }}>Amount</label>
                    <div style={{ border: "0px solid #ccc", width: "100%", height: "auto", borderRadius: "4px", paddingLeft: 10, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {currentItem?.currency} {currentItem?.amount}
                    </div>
                </div>
                <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <label style={{ fontWeight: 600 }}>Payment Date</label>
                    <div style={{ border: "0px solid #ccc", width: "100%", height: "auto", borderRadius: "4px", paddingLeft: 10, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {currentItem?.createdAt}
                    </div>
                </div>
                <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <label style={{ fontWeight: 600 }}>Status</label>
                    <div style={{ border: "0px solid #ccc", width: "100%", height: "auto", borderRadius: "4px", paddingLeft: 10, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {currentItem?.status}
                    </div>
                </div>
            </CustomModal>
            <CustomModal openModal={openModal} setOpenModal={setOpenModal}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ marginBottom: "20px" }}>
                        Make Payment
                    </Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => setOpenModal(false)}>
                        <CloseIcon style={{ fontSize: "20px", color: "#1D8A50" }} />
                    </div>
                </div>
                <CustomInput
                    label="Customer Name"
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                />
                <CustomInput
                    label="Customer Email"
                    value={customerEmail}
                    onChange={(e) => setCustomerEmail(e.target.value)}
                />
                <CustomInput
                    label="Customer Phone"
                    value={customerPhone}
                    onChange={(e) => setCustomerPhone(e.target.value)}
                />
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5, paddingBottom: 10,}}>
                    <Checkbox
                        checked={isChecked}
                        onChange={(event) => {
                            setIsChecked(event.target.checked);
                        }}
                        name="agreed"
                        color="success"
                        sx={{ color: '#00A758' }}
                    /> Allow customer to receipt email/SMS notification
                </div>
                <SelectInput
                    data={[
                        {id: 1, name: "Apapa"},
                        {id: 2, name: "Tincan"},
                        {id: 3, name: "Lekki"},
                        {id: 4, name: "Calabar"},
                        {id: 5, name: "Warri"},
                        {id: 6, name: "Onne"},
                        {id: 7, name: "Rivers"}
                    ]}
                    label="Select Port"
                    value={portName}
                    onChange={(e) => {
                        setPortName(e.target.value);
                    }}
                    placeholder="Select Port"
                />
                <SelectInput
                    data={tariffData}
                    label="Select Payment"
                    value={selectedPayment}
                    defaultValue={currentItem?.name}
                    onChange={(e) => {
                        handleSelectPayment(e.target.value);
                    }}
                    placeholder="Select Payment"
                />
                <CustomInput
                    label={`Quantity (${selectedPaymentObject?.unitOfMeasurement})`}
                    value={itemQuantity}
                    onChange={(e) => setItemQuantity(e.target.value)}
                />
                <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'column', gap: 5, boxSizing: 'border-box' }}>
                    <label style={{ fontWeight: 600 }}>Amount</label>
                    <div style={{ border: "1.5px solid #ccc", width: "100%", height: "45px", borderRadius: "4px", paddingLeft: 10, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {selectedPaymentObject?.currency} {parseFloat(itemQuantity * selectedPaymentObject?.amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                </div>
                <CustomButton loading={buttonLoading} onClick={() => handlePay()}>
                    Submit
                </CustomButton>
            </CustomModal>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 20, marginBottom: 20 }}>
                <Title>Transactions {type === 'null' && name !== 'null' && `(${name})`}</Title>
                <div style={{ width: "150px" }}>
                    <CustomButton onClick={() => setOpenModal(true)}>
                        Make Payment
                    </CustomButton>
                </div>
            </div>
            {type !== 'null' && <div style={{textAlign: 'center', zoom: 0.7, width: '100%', display: 'flex', justifyContent: 'center', paddingBottom: '20px'}}><Title>{name !== 'null' && type === 'agent' ? agentName : name}</Title></div>}
            {showDateTitle && <div style={{textAlign: 'center', fontSize: '16px', width: '100%', textAlign: "center", paddingBottom: '20px'}}><b>From: </b> {dateFrom} - <b>To: </b> {dateTo}</div>}
            {loading ? 
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <CircularProgress color="success" />
                </Box>
                :
                <>
                <TabsContainer>
        <TabsList>
          <Tab
            isSelected={selectedTabCategory === "NGN"}
            onClick={() => setSelectedTabCategory("NGN")}
          >
            NGN
          </Tab>
          <Tab
            isSelected={selectedTabCategory === "USD"}
            onClick={() => setSelectedTabCategory("USD")}
          >
            USD
          </Tab>
        </TabsList>
      </TabsContainer>
                <ReusableTable
                    tableHeader={
                        [
                            { id: 'createdAt', label: 'Date', numeric: false },
                            { id: 'transactionId', label: 'ID', numeric: false },
                            { id: 'portName', label: 'Port', numeric: false },
                            { id: 'customerName', label: 'Customer', numeric: false },
                            { id: 'customerEmail', label: 'Email', numeric: false },
                            { id: 'itemName', label: 'Item', numeric: false },
                            { id: 'itemQuantity', label: 'Quantity', numeric: false },
                            { id: 'currency', label: 'Currency', numeric: false },
                            { id: 'amount', label: 'Amount', numeric: true },
                            { id: 'status', label: 'Status', numeric: false },
                        ]
                    }
                    tableData={filteredData.filter(item => item.currency === selectedTabCategory)}
                    tableAction={tableAction}
                    rowClickAction={rowClickAction}
                    isReport={true}
                    reportTotal={filteredData.filter(item => item.currency === selectedTabCategory).reduce((sum, item) => sum + (item.amount || 0), 0)}
                    datePickerHandler={() => setOpenModalDate(true)}
                    printHandler={handlePrint}
                    currency={selectedTabCategory}
                />
                <div ref={contentRef} className="print-only">
                    <PrintableComponent />
                </div>
                </>
            }
        </>
    );
}


const TabsContainer = styled("div")(() => ({
    display: "flex",
    justifyContent: "center",
    marginBottom: "1rem",
  }));
  
  const TabsList = styled("ul")(() => ({
    display: "flex",
    gap: "2rem",
    borderBottom: "0.1rem solid #ccc",
    listStyle: "none",
  }));
  
  const Tab = styled("li")(({ isSelected }) => ({
    padding: "0.5rem",
    margin: "0.5rem",
    borderRadius: "0.25rem",
    borderBottom: `${isSelected ? "0.25rem solid green" : ""}`,
    color: `${isSelected ? "black" : "#6b7280"}`,
    cursor: "pointer",
  }));
  