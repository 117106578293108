import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import PieChart from "../../components/charts/PieChart";
import PieLabel from "../../components/charts/PieLabel";
import useFetch from "../../hooks/useFetch";

import ReusableTable from "../../components/Table/CustomTable";
import Title from "../../components/text/Title";
import { PORTSCOLORCODES, PORTSLABELS } from "../../components/utils/constants";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import DashboardBarChart from "./components/DashboardBarChart";

export default function DashboardPage() {
  const profile = useSelector((store) => store.user.profile);
  const navigate = useNavigate();
  const [selectedCurrency, setSelectedCurrency] = useState('NGN');
  const [selectedTab, setSelectedTab] = useState("All");
  const [selectedTabCategory, setSelectedTabCategory] =
    useState("transactions");
  const [filteredData, setFilteredData] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0);

  const { data: statistics, sendHttpRequest} = useFetch(`${window.backendUrl}/api/v1/dash/currency/${selectedCurrency}`, {
    executeImmediately: true,
  });
  
  const { data: transactions, loading: loadingTransactions } = useFetch(
    `${window.backendUrl}/api/v1/transactions/all`,
    {
      initialMethod: "GET",
      initialPayload: [],
      executeImmediately: true,
    }
  );

  const { data: customers, loading: loadingCustomers } = useFetch(
    `${window.backendUrl}/api/v1/customers/all`,
    { executeImmediately: true }
  );

  const activeCustomers = customers.filter(
    (c) => c.status === 1
  )?.length;
  const inActiveCustomers = customers.filter(
    (c) => c.status === 0
  )?.length;

  useEffect(() => {
    if (selectedTab === "All") {
      setFilteredData(transactions.filter(item => item.currency === selectedCurrency));
      setTotalRevenue(statistics?.totalAmount);
    }
  }, [selectedTab, statistics?.totalAmount, transactions, selectedCurrency]);

  const transactionForEachPort =
    Object.values(statistics.data || []).map((item) =>
      Math.round((item?.totalAmount / statistics?.totalAmount) * 100)
    ) || [];

  const hexValues = Object.keys(statistics.data || []).map((port) => {
    const colorCode = PORTSCOLORCODES.find((item) => item[port]);
    return colorCode ? colorCode[port] : null;
  });

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    setFilteredData(transactions?.filter((item) => item.portName === tab));
    if (tab !== "All") {
      setTotalRevenue(statistics.data[tab]?.totalAmount);
    } else {
      setTotalRevenue(statistics?.totalAmount);
    }
  };

  const rowClickAction = (row) => {
    navigate(`/transaction/detail/${row?.transactionId}/${row?.createdBy}`);
}

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          marginBottom: 0,
        }}
      >
        <Title>Dashboard</Title>
        <div style={{ width: "150px" }}></div>
      </div>
      <TabsContainer>
      <TabsList>
          <Tab
            isSelected={selectedCurrency === "NGN"}
            onClick={() => {
              setSelectedCurrency("NGN");
              sendHttpRequest(`${window.backendUrl}/api/v1/dash/currency/NGN`);
            }}
          >
            NGN
          </Tab>
          <Tab
            isSelected={selectedCurrency === "USD"}
            onClick={() => {
              setSelectedCurrency("USD");
              sendHttpRequest(`${window.backendUrl}/api/v1/dash/currency/USD`);
            }}
          >
            USD
          </Tab>
        </TabsList>
      </TabsContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          marginBottom: 20,
          height: "auto",
        }}
      >
        <Grid container spacing={2}>
          <Grid key={1} item xs={12} md={6}>
            <CardWrapper color={"#D0F2FF"}>
              <div className="div">
                <CardTitle>Transactions <br/> (Ports)</CardTitle>
                <CardValue>
                  <span>{selectedCurrency === "NGN" && "₦"}{selectedCurrency === "USD" && "$"}</span>
                  {Math.round(totalRevenue || 0)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </CardValue>
              </div>
              <div className="div">
                <div>
                  <PieChart
                    data={{
                      labels: PORTSLABELS,
                      datasets: [
                        {
                          data: transactionForEachPort,
                          backgroundColor: hexValues,
                          //   borderColor: "transparent",
                          //   borderWidth: 0,
                          dataColors: [
                            "#fff",
                            "#fff",
                            "#fff",
                            "#fff",
                            "#fff",
                            "#fff",
                            "#fff",
                          ],
                        },
                      ],
                    }}
                    suffix={"%"}
                    pageRedirect="transactions"
                  />
                </div>
                <div>
                  <PieLabel
                    data={{
                      labels: PORTSLABELS,
                      datasets: [
                        {
                          data: transactionForEachPort,
                          backgroundColor: hexValues,
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            </CardWrapper>
          </Grid>
          <Grid key={2} item xs={12} md={6}>
            <CardWrapper color={"#D0F2FF"}>
              <div className="div">
                <CardTitle>
                  Customers <br /> (status)
                </CardTitle>
                <CardValue>{(customers?.length || 0)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</CardValue>
              </div>
              <div className="div">
                <div>
                  <PieChart
                    data={{
                      labels: ["Active", "Inactive"],
                      datasets: [
                        {
                          data: [activeCustomers, inActiveCustomers],
                          backgroundColor: ["#00a758cc", "#e32d2d"],
                          borderColor: "transparent",
                          borderWidth: 0,
                          dataColors: ["#fff", "#fff"],
                        },
                      ],
                    }}
                    pageRedirect="customers"
                  />
                </div>
                <div>
                  <PieLabel
                    data={{
                      labels: ["Active", "Inactive"],
                      datasets: [
                        {
                          data: [activeCustomers, inActiveCustomers],
                          backgroundColor: ["#00a758cc", "#e32d2d"],
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            </CardWrapper>
          </Grid>
        </Grid>
      </div>
      <DashboardBarChart />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          marginBottom: 0,
        }}
      >
        {selectedTabCategory === "transactions" && <Title>Recent Transactions</Title>}
        {selectedTabCategory === "customers" && <Title>Customers</Title>}
      </div>
      <TabsContainer>
        <TabsList>
          <Tab
            isSelected={selectedTabCategory === "transactions"}
            onClick={() => setSelectedTabCategory("transactions")}
          >
            Transactions
          </Tab>
          <Tab
            isSelected={selectedTabCategory === "customers"}
            onClick={() => setSelectedTabCategory("customers")}
          >
            Customers
          </Tab>
        </TabsList>
      </TabsContainer>
      {  selectedTabCategory === "transactions" && (
              <TabsContainer>
              <TabsList>
                <Tab
                  isSelected={selectedTab === "All"}
                  onClick={() => handleTabClick("All")}
                >
                  All
                </Tab>
                <Tab
                  isSelected={selectedTab === "Apapa"}
                  onClick={() => handleTabClick("Apapa")}
                >
                  Apapa
                </Tab>
                <Tab
                  isSelected={selectedTab === "Tincan"}
                  onClick={() => handleTabClick("Tincan")}
                >
                  Tincan
                </Tab>
                <Tab
                  isSelected={selectedTab === "Lekki"}
                  onClick={() => handleTabClick("Lekki")}
                >
                  Lekki
                </Tab>
                <Tab
                  isSelected={selectedTab === "Calabar"}
                  onClick={() => handleTabClick("Calabar")}
                >
                  Calabar
                </Tab>
                <Tab
                  isSelected={selectedTab === "Warri"}
                  onClick={() => handleTabClick("Warri")}
                >
                  Warri
                </Tab>
                <Tab
                  isSelected={selectedTab === "Onne"}
                  onClick={() => handleTabClick("Onne")}
                >
                  Onne
                </Tab>
                <Tab
                  isSelected={selectedTab === "Rivers"}
                  onClick={() => handleTabClick("Rivers")}
                >
                  Rivers
                </Tab>
              </TabsList>
            </TabsContainer>
      )}
      {selectedTabCategory === "transactions" && (
        <ReusableTable
          tableHeader={[
            { id: "customerName", label: "Customer", numeric: false },
            { id: "portName", label: "Port", numeric: false },
            { id: "itemName", label: "Item", numeric: false },
            { id: "currency", label: "Currency", numeric: false },
            { id: "amount", label: "Amount", numeric: true },
            { id: "createdAt", label: "Date", numeric: false },
            { id: "status", label: "Status", numeric: false },
          ]}
          tableData={filteredData}
          rowClickAction={rowClickAction}
        />
      )}
      {selectedTabCategory === "customers" && (
        <ReusableTable
          tableHeader={[
            { id: 'name', label: 'Name', numeric: false },
            { id: 'phone', label: 'Phone', numeric: false },
            { id: 'email', label: 'Email', numeric: false },
            { id: 'status', label: 'Status', numeric: false}
          ]}
          tableData={customers ? customers : []}
        />
      )}
    </>
  );
}

const CardWrapper = styled("div")(({ color }) => ({
  backgroundColor: color,
  width: "100%",
  height: "240px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  borderRadius: "8px",
  padding: "20px",
  boxSizing: "border-box",
  gap: 20,
  "& .div": {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "space-between",
  },
}));

const CardTitle = styled("div")(() => ({
  color: "#252421",
  fontSize: "22px",
  fontWeight: 600,
}));

const CardValue = styled("h4")(() => ({
  color: "#000",
  fontSize: "48px",
  fontWeight: 600,
}));

const TabsContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: "1rem",
}));

const TabsList = styled("ul")(() => ({
  display: "flex",
  gap: "2rem",
  borderBottom: "0.1rem solid #ccc",
  listStyle: "none",
}));

const Tab = styled("li")(({ isSelected }) => ({
  padding: "0.5rem",
  margin: "0.5rem",
  borderRadius: "0.25rem",
  borderBottom: `${isSelected ? "0.25rem solid green" : ""}`,
  color: `${isSelected ? "black" : "#6b7280"}`,
  cursor: "pointer",
}));
