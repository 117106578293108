import React, { useState, useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Title from "../../components/text/Title";

export default function RealTimePage() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${window.backendUrl}/api/v1/transactions/all`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result?.code === 200) {
                    setData(result?.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, []);


    useEffect(() => {
        const fetchData = () => {
            const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        setLoading(true);
        fetch(`${window.backendUrl}/api/v1/transactions/all`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result?.code === 200) {
                    setData(result?.data?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
        };
    
        const intervalId = setInterval(fetchData, 30000); 

        fetchData();
    
        return () => clearInterval(intervalId);
      }, []);

    return (
        <>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 20, marginBottom: 0 }}>
                <Title>Real Time</Title>
                <Title>Total: {data?.length}</Title>
            </div>
            
            {loading ? 
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <CircularProgress color="success" />
                </Box>
                :
                <>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 0, marginBottom: 20 }}>
                    <Title>Amount (NGN): {data?.filter(item => item.currency === 'NGN').reduce((sum, item) => sum + (item.amount || 0), 0)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Title>
                    <Title>Amount (USD): {data?.filter(item => item.currency === 'USD').reduce((sum, item) => sum + (item.amount || 0), 0)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Title>
                </div>
                <table width={"100%"} border={0}>
                <tr className="header">
                    <td align="center">S/N</td>
                        <td align="center">Transaction_ID</td>
                        <td align="center">Port_Name</td>
                        <td align="center">Customer_Name</td>
                        <td align="center" style={{width: "50px"}}>Customer_Email</td>
                        <td align="center" style={{width: "200px"}}>Item</td>
                        <td align="center">Quantity</td>
                        <td align="center">Currency</td>
                        <td align="center">Amount</td>
                        <td align="center">Date</td>
                    </tr>
                    {data?.map((item, index) => (
                        <tr key={index} className={(index + 1) % 2 === 0 ? 'even' : 'odd'}>
                            <td>{index + 1}</td>
                            <td>{item?.transactionId}</td>
                            <td>{item?.portName}</td>
                            <td>{item?.customerName}</td>
                            <td>{item?.customerEmail}</td>
                            <td>{item?.itemName}</td>
                            <td>{item?.itemQuantity}</td>
                            <td>{item?.currency}</td>
                            <td>{item?.amount?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                            <td>{item?.createdAt}</td>
                        </tr>
                    ))}
                </table>
                </>
            }
        </>
    );
}