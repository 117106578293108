import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import ReusableTable from "../../components/Table/CustomTable";
import Title from "../../components/text/Title";
import CustomButton from "../../components/button/CustomButton";
import SelectInput from "../../components/input/SelectInput";
import CustomInput from "../../components/input/CustomInput";
import CustomModal from "../../components/modal/CustomModal";
import MessageAlert from "../../components/alerts/MessageAlert";
import { handleAlertClose } from '../../components/alerts/alertUtils';
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function AssetPage() {
    const profile = useSelector((store) => store.user.profile);
    const navigate = useNavigate();
    if(profile?.userType !== "admin"){
        navigate("/");
    }
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("error");
    const [openModal, setOpenModal] = useState(false);
    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [currentItem, setCurrentItem] = useState({});
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [idNumber, setIdNumber] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [location, setLocation] = useState('');
    const [quantity, setQuantity] = useState('');
    const [status, setStatus] = useState('');
    const [idNumberEdit, setIdNumberEdit] = useState('');
    const [nameEdit, setNameEdit] = useState('');
    const [descriptionEdit, setDescriptionEdit] = useState('');
    const [locationEdit, setLocationEdit] = useState('');
    const [quantityEdit, setQuantityEdit] = useState('');
    const [statusEdit, setStatusEdit] = useState('');

    const items = [
        { id: 1, idNumber: "NPA001", name: "Forklift", description: "Heavy-duty forklift for container handling", quantity: 3, status: "Operational" },
        { id: 2, idNumber: "NPA002", name: "Cargo Crane", description: "50-ton cargo crane", quantity: 2, status: "Under Maintenance" },
        { id: 3, idNumber: "NPA003", name: "Tugboat", description: "Assists with maneuvering large vessels", quantity: 1, status: "Operational" },
        { id: 4, idNumber: "NPA004", name: "Container Trailer", description: "Used for transporting containers within the port", quantity: 5, status: "Operational" },
        { id: 5, idNumber: "NPA005", name: "Security Patrol Vehicle", description: "Vehicle for port security patrols", quantity: 8, status: "Operational" },
        { id: 6, idNumber: "NPA006", name: "Fuel Tanker", description: "Tanker for refueling vessels", quantity: 1, status: "Out of Service" },
        { id: 7, idNumber: "NPA007", name: "Fire Truck", description: "Emergency fire truck", quantity: 2, status: "Operational" },
        { id: 8, idNumber: "NPA008", name: "Dockside Generator", description: "Backup generator for dockside power", quantity: 4, status: "Operational" },
        { id: 9, idNumber: "NPA009", name: "Harbor Pilot Boat", description: "Boat for transporting harbor pilots to vessels", quantity: 2, status: "Under Repair" },
        { id: 10, idNumber: "NPA010", name: "Rubber-Tyred Gantry Crane", description: "Crane for moving containers", quantity: 3, status: "Operational" }
    ];

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${window.backendUrl}/api/v1/assets/all`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result?.code === 200) {
                    setData(result?.data);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, []);

    const handleAdd = () => {
        if (!idNumber || !name || !description || !location || !quantity || !status) {
            setAlertMessage("All fields are required!");
            setAlertType("error");
            setAlertOpen(true);
        } else {
            setButtonLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                idNumber, name, description, location, quantity, status
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(`${window.backendUrl}/api/v1/assets/add`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result?.code === 200) {
                        setAlertMessage(result?.message);
                        setAlertType("success");
                        setAlertOpen(true);
                    } else {
                        setAlertMessage(result?.message);
                        setAlertType("error");
                        setAlertOpen(true);
                    }
                    setButtonLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setAlertMessage("An error occured, try again !");
                    setAlertType("error");
                    setAlertOpen(true);
                    setButtonLoading(false);
                });
        }
    }

    const handleEdit = () => {
        if (!idNumberEdit || !nameEdit || !descriptionEdit || !locationEdit || !quantityEdit || !statusEdit) {
            setAlertMessage("All fields are required!");
            setAlertType("error");
            setAlertOpen(true);
        } else {
            setButtonLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                id: currentItem?._id,
                idNumber: idNumberEdit, 
                name: nameEdit, 
                description: descriptionEdit, 
                location: locationEdit, 
                quantity: quantityEdit, 
                status: statusEdit
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(`${window.backendUrl}/api/v1/assets/edit`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result?.code === 200) {
                        setAlertMessage(result?.message);
                        setAlertType("success");
                        setAlertOpen(true);
                    } else {
                        setAlertMessage(result?.message);
                        setAlertType("error");
                        setAlertOpen(true);
                    }
                    setButtonLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setAlertMessage("An error occured, try again !");
                    setAlertType("error");
                    setAlertOpen(true);
                    setButtonLoading(false);
                });
        }
    }

    const tableAction = (row) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', gap: 5}}>
                <button 
                    style={{backgroundColor: "#00A758", color: "#fff", cursor: "pointer", padding: "5px 10px", borderRadius: 2, border: 0}} 
                    onClick={() => {
                        setCurrentItem(row);
                        //setOpenDetailModal(true);
                        navigate(`/asset-detail/${row?._id}`);
                    }}
                    >
                    View
                </button>
                <button
                    style={{ backgroundColor: "#00A758", color: "#fff", cursor: "pointer", padding: "5px 10px", borderRadius: 2, border: 0 }}
                    onClick={() => {
                        // setIdNumberEdit(row.idNumber);
                        // setNameEdit(row.name);
                        // setDescriptionEdit(row.description);
                        // setQuantityEdit(row.quantity);
                        // setStatusEdit(row.status);
                        // setLocationEdit(row.location);
                        setCurrentItem(row);
                        // setOpenEditModal(true);
                        navigate(`/edit-asset/${row._id}`);
                    }}>
                    Edit
                </button>
            </div>
        );
    };

    const rowClickAction = (row) => {
        navigate(`/asset-detail/${row?._id}`)
    };

    const [openHistoryAddModal, setOpenHistoryAddModal] = useState(false);
    const [openHistoryEditModal, setOpenHistoryEditModal] = useState(false);
    const [assetHistory, setAssetHistory] = useState([
        {_id: 1, idNumber: "NPA0001", from: "Apapa Port", to: "Warri Port", status: "On Route", date: "2024-10-10"},
    ]);
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [historyStatus, setHistoryStatus] = useState("");
    const [assetFeeAmount, setAssetFeeAmount] = useState("");
    const [assetHistoryDescription, setAssetHistoryDescription] = useState("");
    const [fromEdit, setFromEdit] = useState("");
    const [toEdit, setToEdit] = useState("");
    const [historyStatusEdit, setHistoryStatusEdit] = useState("");
    const [assetFeeAmountEdit, setAssetFeeAmountEdit] = useState("");
    const [assetHistoryDescriptionEdit, setAssetHistoryDescriptionEdit] = useState("");
    const [currentHistoryItem, setCurrentHistoryItem] = useState("");

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${window.backendUrl}/api/v1/assets/history/id/${currentItem?.idNumber}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result?.code === 200) {
                    setAssetHistory(result?.data);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, [currentItem]);

    const handleAddHistory = () => {
        if (!from || !to || !historyStatus || !assetFeeAmount) {
            setAlertMessage("All fields are required!");
            setAlertType("error");
            setAlertOpen(true);
        } else {
            setButtonLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                idNumber: currentItem?.idNumber, from, to, status: historyStatus, amount: assetFeeAmount, description: assetHistoryDescription
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(`${window.backendUrl}/api/v1/assets/history/add`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result?.code === 200) {
                        setAssetHistory(result?.data);
                        setAlertMessage(result?.message);
                        setAlertType("success");
                        setAlertOpen(true);
                        setOpenHistoryAddModal(false);
                    } else {
                        setAlertMessage(result?.message);
                        setAlertType("error");
                        setAlertOpen(true);
                    }
                    setButtonLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setAlertMessage("An error occured, try again !");
                    setAlertType("error");
                    setAlertOpen(true);
                    setButtonLoading(false);
                });
        }
    }

    const handleEditHistory = () => {
        if (!fromEdit || !toEdit || !historyStatusEdit || !assetFeeAmountEdit) {
            setAlertMessage("All fields are required!");
            setAlertType("error");
            setAlertOpen(true);
        } else {
            setButtonLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                id: currentHistoryItem?._id, idNumber: currentHistoryItem?.idNumber, from: fromEdit, to: toEdit, status: historyStatusEdit, amount: assetFeeAmountEdit, description: assetHistoryDescriptionEdit
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(`${window.backendUrl}/api/v1/assets/history/edit`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result?.code === 200) {
                        setAssetHistory(result?.data);
                        setAlertMessage(result?.message);
                        setAlertType("success");
                        setAlertOpen(true);
                        setOpenHistoryEditModal(false);
                    } else {
                        setAlertMessage(result?.message);
                        setAlertType("error");
                        setAlertOpen(true);
                    }
                    setButtonLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setAlertMessage("An error occured, try again !");
                    setAlertType("error");
                    setAlertOpen(true);
                    setButtonLoading(false);
                });
        }
    }

    return (
        <>
            <MessageAlert
                message={alertMessage}
                open={alertOpen}
                onClose={handleAlertClose(setAlertOpen)}
                type={alertType}
            />
            <CustomModal openModal={openDetailModal} setOpenModal={setOpenDetailModal}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ marginBottom: "20px" }}>
                        Asset Details - ID {currentItem?.idNumber}
                    </Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => setOpenDetailModal(false)}>
                        <CloseIcon style={{ fontSize: "20px", color: "#1D8A50" }} />
                    </div>
                </div>
                <div style={{ marginBottom: 0, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <label style={{ fontWeight: 600 }}>Name</label>
                    <div style={{ border: "0px solid #ccc", width: "100%", height: "45px", borderRadius: "4px", paddingLeft: 10, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {currentItem?.name}
                    </div>
                </div>
                <div style={{ marginBottom: 0, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <label style={{ fontWeight: 600 }}>Description</label>
                    <div style={{ border: "0px solid #ccc", width: "100%", height: "45px", borderRadius: "4px", paddingLeft: 10, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {currentItem?.description}
                    </div>
                </div>
                <div style={{ marginBottom: 0, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <label style={{ fontWeight: 600 }}>Location</label>
                    <div style={{ border: "0px solid #ccc", width: "100%", height: "45px", borderRadius: "4px", paddingLeft: 10, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {currentItem?.location}
                    </div>
                </div>
                <div style={{ marginBottom: 0, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <label style={{ fontWeight: 600 }}>Status</label>
                    <div style={{ border: "0px solid #ccc", width: "100%", height: "45px", borderRadius: "4px", paddingLeft: 10, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {currentItem?.status}
                    </div>
                </div>
                <div style={{width: "100%"}}>
                    <legend><b>Asset History</b></legend>
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "right" }}>
                        <div style={{ width: "200px", marginBottom: "10px" }}>
                            <CustomButton size="small" onClick={() => setOpenHistoryAddModal(true)}>
                                Hire Asset
                            </CustomButton>
                        </div>
                    </div>
                    <div style={{width: "100%", height: "30vh", overflow: "auto"}}>
                        <table width="100%">
                            <tr style={{background: 'gray', color: '#fff'}}>
                                <td>SN</td>
                                <td>AssetID</td>
                                <td>From</td>
                                <td>To</td>
                                <td>Fee</td>
                                <td>Description</td>
                                <td>Status</td>
                                <td>Date</td>
                                <td>Action</td>
                            </tr>
                            {assetHistory.map((item, index) => (
                                <tr key={index} style={index % 2 === 0 ? {backgroundColor: "#fff"} : {backgroundColor: "#ccc"}}>
                                    <td>{index+1}</td>
                                    <td>{item?.idNumber}</td>
                                    <td>{item?.from}</td>
                                    <td>{item?.to}</td>
                                    <td>{item?.amount}</td>
                                    <td>{item?.description}</td>
                                    <td>{item?.status}</td>
                                    <td>{item?.createdAt}</td>
                                    <td>
                                        <button 
                                            style={{backgroundColor: "#00A758", color: "#fff", cursor: "pointer", padding: "5px 10px", borderRadius: 2, border: 0}} 
                                            onClick={() => {
                                                setCurrentHistoryItem(item);
                                                setFromEdit(item?.from);
                                                setToEdit(item?.to);
                                                setHistoryStatusEdit(item?.status);
                                                setAssetFeeAmountEdit(item?.amount);
                                                setAssetHistoryDescriptionEdit(item?.description)
                                                setOpenHistoryEditModal(true);
                                            }}
                                            >
                                            Edit
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </div>
                </div>
            </CustomModal>
            <CustomModal openModal={openModal} setOpenModal={setOpenModal} >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ marginBottom: "20px" }}>
                        Add Asset
                    </Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => setOpenModal(false)}>
                        <CloseIcon style={{ fontSize: "20px", color: "#1D8A50" }} />
                    </div>
                </div>
                <CustomInput
                    label="Asset ID"
                    placeholder="Enter the asset ID"
                    value={idNumber}
                    onChange={(e) => setIdNumber(e.target.value)}
                />
                <CustomInput
                    label="Name"
                    placeholder="Enter the asset name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <CustomInput
                    label="Description"
                    placeholder="Enter the asset description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
                <CustomInput
                    label="Quantity"
                    placeholder="Enter the asset quantity"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                />
                <SelectInput
                    data={[
                        { id: 1, name: "Operational" },
                        { id: 2, name: "Under Maintenance" },
                        { id: 3, name: "Out of Service" },
                        { id: 4, name: "Under Repair" }
                    ]}
                    label="Status"
                    value={status}
                    onChange={(e) => {
                        setStatus(e.target.value);
                    }}
                    placeholder="Select Payment"
                />
                <CustomInput
                    label="Location"
                    placeholder="Enter the asset location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                />
                <CustomButton loading={buttonLoading} disabled={buttonLoading} onClick={() => handleAdd()}>
                    Submit
                </CustomButton>
            </CustomModal>
            <CustomModal openModal={openEditModal} setOpenModal={setOpenEditModal} >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ marginBottom: "20px" }}>
                        Edit Asset
                    </Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => setOpenEditModal(false)}>
                        <CloseIcon style={{ fontSize: "20px", color: "#1D8A50" }} />
                    </div>
                </div>
                <CustomInput
                    label="Asset ID"
                    placeholder="Enter the asset ID"
                    value={idNumberEdit}
                    onChange={(e) => setIdNumberEdit(e.target.value)}
                />
                <CustomInput
                    label="Name"
                    placeholder="Enter the asset name"
                    value={nameEdit}
                    onChange={(e) => setNameEdit(e.target.value)}
                />
                <CustomInput
                    label="Description"
                    placeholder="Enter the asset description"
                    value={descriptionEdit}
                    onChange={(e) => setDescriptionEdit(e.target.value)}
                />
                <CustomInput
                    label="Quantity"
                    placeholder="Enter the asset quantity"
                    value={quantityEdit}
                    onChange={(e) => setQuantityEdit(e.target.value)}
                />
                <SelectInput
                    data={[
                        { id: 1, name: "Operational" },
                        { id: 2, name: "Under Maintenance" },
                        { id: 3, name: "Out of Service" },
                        { id: 4, name: "Under Repair" }
                    ]}
                    label="Status"
                    value={statusEdit}
                    onChange={(e) => {
                        setStatusEdit(e.target.value);
                    }}
                    placeholder="Select Payment"
                />
                <CustomInput
                    label="Location"
                    placeholder="Enter the asset location"
                    value={locationEdit}
                    onChange={(e) => setLocationEdit(e.target.value)}
                />
                <CustomButton loading={buttonLoading} disabled={buttonLoading} onClick={() => handleEdit()}>
                    Update
                </CustomButton>
            </CustomModal>

            <CustomModal openModal={openHistoryAddModal} setOpenModal={setOpenHistoryAddModal} >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ marginBottom: "20px" }}>
                        Hire Asset
                    </Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => setOpenHistoryAddModal(false)}>
                        <CloseIcon style={{ fontSize: "20px", color: "#1D8A50" }} />
                    </div>
                </div>
                <CustomInput
                    label="From"
                    placeholder="Enter the asset from"
                    value={from}
                    onChange={(e) => setFrom(e.target.value)}
                />
                <CustomInput
                    label="To"
                    placeholder="Enter the asset to"
                    value={to}
                    onChange={(e) => setTo(e.target.value)}
                />
                <CustomInput
                    label="Fee Amount"
                    placeholder="Enter the fee amount"
                    value={assetFeeAmount}
                    onChange={(e) => setAssetFeeAmount(e.target.value)}
                />
                <CustomInput
                    label="Hire to"
                    placeholder="Enter the hire to"
                    value={assetHistoryDescription}
                    onChange={(e) => setAssetHistoryDescription(e.target.value)}
                />
                <SelectInput
                    data={[
                        { id: 1, name: "Prepare to Move" },
                        { id: 2, name: "On Route" },
                        { id: 3, name: "At Destination" },
                        { id: 4, name: "Returned"},
                    ]}
                    label="Status"
                    value={historyStatus}
                    onChange={(e) => {
                        setHistoryStatus(e.target.value);
                    }}
                    placeholder="Select Payment"
                />
                <CustomButton loading={buttonLoading} disabled={buttonLoading} onClick={() => handleAddHistory()}>
                    Submit
                </CustomButton>
            </CustomModal>
            <CustomModal openModal={openHistoryEditModal} setOpenModal={setOpenHistoryEditModal} >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ marginBottom: "20px" }}>
                        Edit Asset History
                    </Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => setOpenHistoryEditModal(false)}>
                        <CloseIcon style={{ fontSize: "20px", color: "#1D8A50" }} />
                    </div>
                </div>
                <CustomInput
                    label="From"
                    placeholder="Enter the asset from"
                    value={fromEdit}
                    onChange={(e) => setFromEdit(e.target.value)}
                />
                <CustomInput
                    label="To"
                    placeholder="Enter the asset to"
                    value={toEdit}
                    onChange={(e) => setToEdit(e.target.value)}
                />
                <CustomInput
                    label="Fee Amount"
                    placeholder="Enter the fee amount"
                    value={assetFeeAmountEdit}
                    onChange={(e) => setAssetFeeAmountEdit(e.target.value)}
                />
                <CustomInput
                    label="Description"
                    placeholder="Enter the description"
                    value={assetHistoryDescriptionEdit}
                    onChange={(e) => setAssetHistoryDescriptionEdit(e.target.value)}
                />
                <SelectInput
                    data={[
                        { id: 1, name: "Prepare to Move" },
                        { id: 2, name: "On Route" },
                        { id: 3, name: "At Destination" }
                    ]}
                    label="Status"
                    value={historyStatusEdit}
                    onChange={(e) => {
                        setHistoryStatusEdit(e.target.value);
                    }}
                />
                <CustomButton loading={buttonLoading} disabled={buttonLoading} onClick={() => handleEditHistory()}>
                    Update
                </CustomButton>
            </CustomModal>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 20, marginBottom: 20 }}>
                <Title>Assets</Title>
                <div style={{ width: "150px" }}>
                    <CustomButton onClick={() => navigate("/add-asset")}>
                        Add Asset
                    </CustomButton>
                </div>
            </div>
            {loading ? 
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <CircularProgress color="success" />
                </Box>
                :
                <ReusableTable
                    tableHeader={
                        [
                            { id: 'idNumber', label: 'ID', numeric: false },
                            { id: 'name', label: 'Name', numeric: false },
                            { id: 'description', label: 'Description', numeric: false },
                            { id: 'location', label: 'Location', numeric: false },
                            { id: 'quantity', label: 'Quantity', numeric: false },
                            { id: 'status', label: 'Status', numeric: false },
                        ]
                    }
                    tableData={data}
                    tableAction={tableAction}
                    rowClickAction={rowClickAction}
                />
            }
        </>
    );
}