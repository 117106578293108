import React, { useState, useEffect, useRef } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import ReusableTable from "../../components/Table/CustomTable";
import Title from "../../components/text/Title";
import MessageAlert from "../../components/alerts/MessageAlert";
import { handleAlertClose } from '../../components/alerts/alertUtils';
import { useLocation, useNavigate } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';
import Logo from "../../assets/logo.png";
import CustomModal from "../../components/modal/CustomModal";
import CustomButton from "../../components/button/CustomButton";

export default function ReportsByPortPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const name = decodeURIComponent(queryParams.get('name'));
    const type = decodeURIComponent(queryParams.get('type'));
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("error");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [filteredData, setFilteredData] = useState([]);

    const handleClick = () => {
        setOpenModal(true);
    };

    const tableAction = (row) => {
        return (
            <button
                style={{ backgroundColor: "#00A758", color: "#fff", cursor: "pointer", padding: "5px 10px", borderRadius: 2, border: 0 }}
                onClick={() => {    
                    navigate(`/transactions?name=${encodeURIComponent(row?.name)}&type=${encodeURIComponent(type)}`);
                }}>
                View
            </button>
        );
    };

    useEffect(() => {
        const types = ['tariff', 'port', 'agent'];
        if (!types.includes(type)) {
            type = 'tariff';
        }
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        setLoading(true);
        setData([]);
        fetch(`${window.backendUrl}/api/v1/reports/items/${type}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result?.code === 200) {
                    setData(result?.data);
                    setFilteredData(result?.data);
                } else {

                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, [type]);

    const PrintableComponent = () => {
        return (
            <div style={{ border: '0px solid red', width: '100%', padding: '20px', boxSizing: 'border-box' }}>
                <div style={{ textAlign: 'center' }}><img src={Logo} alt="Logo" width={100} style={{ alignSelf: "center" }} /></div>
                <h2 style={{ color: "#00701F", textAlign: "center", margin: 0, padding: 0 }}>NIGERIAN PORTS AUTHORITY</h2>
                <h2 style={{ textAlign: 'center', marginTop: 0, paddingTop: 0 }}>Report</h2>
                <table width={"100%"}>
                    <tr>
                        <td align="left" style={{ paddingBottom: '15px', width: "50%" }}><b>Total Items:</b> {data.length}</td>
                        <td align="right" style={{ paddingBottom: '15px', width: "50%" }}><b>Total Amount:</b> {data.reduce((sum, tariff) => sum + (tariff.totalAmountGenerated || 0), 0)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    </tr>
                </table>
                <table width={"100%"} border={0}>
                    {type === 'agent' ?
                        <>
                            <tr className="header">
                                <td align="center">S/N</td>
                                <td align="center">Name</td>
                                <td align="center">Email</td>
                                <td align="center">Amount</td>
                            </tr>
                            {data?.map((item, index) => (
                                <tr key={index} className={(index + 1) % 2 === 0 ? 'even' : 'odd'}>
                                    <td>{index + 1}</td>
                                    <td>{item?.name}</td>
                                    <td>{item?.email}</td>
                                    <td>{item?.totalAmountGenerated?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                </tr>
                            ))}
                        </>
                        :
                        <>
                            <tr className="header">
                                <td align="center">S/N</td>
                                <td align="center">Name</td>
                                <td align="center">Currency</td>
                                <td align="center">Amount</td>
                            </tr>
                            {data?.map((item, index) => (
                                <tr key={index} className={(index + 1) % 2 === 0 ? 'even' : 'odd'}>
                                    <td>{index + 1}</td>
                                    <td>{item?.name}</td>
                                    <td>{item?.currency}</td>
                                    <td>{item?.totalAmountGenerated?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                </tr>
                            ))}
                        </>
                    }
                </table>
            </div>
        );
    }

    const contentRef = useRef(null);
    const handlePrint = useReactToPrint({ contentRef });

    const [showDateTitle, setShowDateTitle] = useState(false);

    const handleDateFilter = async () => {
        if(!dateFrom || !dateTo){
            setAlertMessage("Date range is required!");
            setAlertOpen(true);
        } else {
            const types = ['tariff', 'port', 'agent'];
        if (!types.includes(type)) {
            type = 'tariff';
        }
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        setLoading(true);
        setData([]);
        fetch(`${window.backendUrl}/api/v1/reports/items-date/${type}/${dateFrom}/${dateTo}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result?.code === 200) {
                    setData(result?.data);
                    setFilteredData(result?.data);
                    setShowDateTitle(true);
                    setOpenModal(false);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });

            // const dateFromConverted = new Date(dateFrom);
            // const dateToConverted = new Date(dateTo);

            // const filteredDataDate = data.filter(item => {
            //     const createdAt = new Date(item.createdAt);
            //     return createdAt >= dateFromConverted && createdAt <= dateToConverted;
            // });
        }
    }

    return (
        <>
            <MessageAlert
                message={alertMessage}
                open={alertOpen}
                onClose={handleAlertClose(setAlertOpen)}
                type={alertType}
            />
            <CustomModal openModal={openModal} setOpenModal={setOpenModal}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '15px', boxSizing: 'border-box'}}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '15px', width: '50%'}}>
                        <input type="date" onChange={(e) => setDateFrom(e.target.value)} style={{ width: '100%', height: '45px', borderRadius: 8, border: '1px solid grey', padding: 10, boxSizing: 'border-box' }} />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '15px', width: '50%'}}>
                        <input type="date" onChange={(e) => setDateTo(e.target.value)} style={{ width: '100%', height: '45px', borderRadius: 8, border: '1px solid grey', padding: 10, boxSizing: 'border-box' }} />
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '15px', boxSizing: 'border-box', marginTop: '20px',}}>
                    <CustomButton onClick={() => handleDateFilter()}>Filter</CustomButton>
                </div>
            </CustomModal>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 20, marginBottom: 20 }}>
                <Title>Reports by Ports {showDateTitle && <span style={{textAlign: 'center', fontSize: '16px', width: '100%', textAlign: "center", paddingBottom: '20px'}}> (<b>From: </b> {dateFrom} - <b>To: </b> {dateTo})</span>}</Title>
                <div style={{ width: "150px" }}>

                </div>
            </div>
            {name !== 'null' && <div style={{textAlign: 'center', zoom: 0.7, width: '100%', display: 'flex', justifyContent: 'center', paddingBottom: '20px'}}><Title>{name}</Title></div>}
            
            {loading ?
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <CircularProgress color="success" />
                </Box>
                :
                <ReusableTable
                    tableHeader={type === 'agent' ?
                        [
                            { id: 'name', label: 'Name', numeric: false },
                            { id: 'email', label: 'Email', numeric: false },
                            { id: 'totalAmountGenerated', label: 'Amount Generated', numeric: true },
                        ]
                        :
                        [
                            { id: 'name', label: 'Name', numeric: false },
                            { id: 'currency', label: 'Currency', numeric: false },
                            { id: 'totalAmountGenerated', label: 'Amount Generated', numeric: true },
                        ]
                    }
                    tableData={data}
                    tableAction={tableAction}
                    isReport={true}
                    reportTotal={data.reduce((sum, tariff) => sum + (tariff.totalAmountGenerated || 0), 0)}
                    datePickerHandler={handleClick}
                    printHandler={handlePrint}
                />
            }
            <div ref={contentRef} className="print-only">
                <PrintableComponent />
            </div>
        </>
    );
}